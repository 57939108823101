<template>
  <div class="admin-menu UserAsideMenu">
    <div class="admin-menu-content">
      <div class="admin-menu-user" v-if="$user">
        <div class="admin-menu-user-avatar tcenter">
          <UserAvatar :user="$user" size="60px" @click="goToUserFeed($user)" cursor="pointer" />
          <div class="admin-menu-user-name">{{ $user.name }}</div>
          <div class="admin-menu-user-role" tcenter>
            <small><MyRoleLabel /></small>
          </div>
        </div>
      </div>
      <div class="admin-menu-options" v-if="$screen.width < 1040">
        <router-link class="primary-circle userProfileButton" :to="`${$isCreator ? `/${$user.user}` : $links['my-profile']}`">
          <iconic name="user" />
        </router-link>
        <a class="primary-circle" @click="UserAdminMenu"><iconic name="bars" /></a>
      </div>
      <div class="admin-menu-nav" v-else>
        <UserQuikMenuDefault />

        <Collapse
          icon="ellipsis-h"
          :title="$locale['my_content']"
          :class="`${$isView('content') ? 'onview' : ''}`"
          ref="contentMenu"
          @open="menuOpen('contentMenu')"
        >
          <UserContentMenu _view="content" class="UserContentMenu" />
        </Collapse>
      </div>
    </div>
  </div>
</template>

<script>
import AdminMenuOptions from "../admin/AdminMenuOptions.vue";
export default {
  mixins: [AdminMenuOptions],
  components: {
    UserQuikMenuDefault: () => import("./UserQuikMenuDefault.vue"),
    UserContentMenu: () => import("./UserContentMenu.vue"),
  },
  methods: {
    goFilter: function(filter) {
      const currentPath = this.$creatorFeed ? this.$creatorFeed.user : "/";
      this.$router.push({ path: currentPath, query: filter }).catch(() => {});
      this.$global.scrollToEl({ el: ".main-feed", topgap: 60 });
    },
    AdminMenuMobile: function() {
      this.$store.commit("AdminMenuMobile", true);
    },
  },
};
</script>
